import { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GlobalContext } from 'contexts/GlobalContext';
import { StepContext } from 'contexts/StepContext';
import ResultLoading from '../images/result-loading-blue.gif'
import { checkPaymentStatusAPI } from 'utils/fetchAPI';
import useQuery from 'hooks/useQuery';
import { typeMapping } from 'constants/steps';
import { ApplicationType } from 'types';

const Result = () => {
  const navigator = useNavigate();
  const { type = ApplicationType.Pay } = useParams();
  const { setCurrentStep } = useContext(StepContext);
  const { updateBillDetail, fetchInformation } = useContext(GlobalContext);
  const query = useQuery();
  const stepList = typeMapping[type];

  useEffect(() => {
    setCurrentStep(stepList[stepList.length - 1].step);
  }, [stepList, setCurrentStep]);

  useEffect(() => {
    (async () => {
      try {
        const bill_id = query.get('order_number') || localStorage.getItem('bill_id') || '';
        const area_id = localStorage.getItem('lot_code');
        const response = await checkPaymentStatusAPI({ bill_id });
        const result = await response.json();
        const {
          payment_status = -1,
          bill_token,
          lot_code = '',
          operator_code = '',
        } = result;

        fetchInformation({
          lot_code,
          operator_code,
          area_id,
        });

        updateBillDetail({
          bill_lot_code: lot_code,
          bill_token,
          bill_id,
          operator_code,
        });
        navigator(`/${type}/${payment_status === 0 ? 'success' : 'failed'}`);
      } catch (err) {
        console.error(`err : ${err}`);
        // navigator(`/not-found`);
        navigator(`/${type}/failed`);
      }
    })();
  }, [navigator, type, query, updateBillDetail, fetchInformation]);

  return <div className="p-4 pt-[64px] text-center h-[calc(100vh-132px)] flex  items-center flex-col text-base">
    <>
      <img src={ResultLoading} width="85px" height="85px" alt="loading" className='mb-7' />
      <p>交易確認中，請耐心等候</p>
    </>
  </div>;
}
export default Result;